/*---------------------------
    Fonts
----------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

// fonts
$base-font-size: 15;
$base-font: 'Cairo';
$heading-font: 'Cairo';

// color
$dark-gray: #001635;
$body-color: #525252;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #af914e;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-dark: #001635;
$section-bg-dark-2: #001635;
$section-bg-color: #fafbfe;
$section-bg-color-s2: #f5f5f5;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #CEE0F2;

// dark mode
.dark-mode {
  $white: #000 !important;
  $dark-gray: #fff;
  $body-color: #a0a0a0;
  $light: #565656;
  $black: #fff;
  $small-black: #ccc;
  $cyan: #6e6e6e;

  $theme-primary-color: #af914e; // You can modify this color if needed
  $theme-primary-color-s2: #071e93; // You can modify this color if needed
  $body-bg-color: $dark-gray;
  $section-bg-dark: #172533;
  $section-bg-dark-2: #1E2E3E;
  $section-bg-color: #333;
  $section-bg-color-s2: #2c2c2c;
  $text-color: #e0e0e0;
  $text-light-color: #d4d4d4;
  $heading-color: $white;
  $border-color: #555;
  $border-color-s2: #444;
}
